<template>
  <div>
    <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div class="text-center">
        <h2 class="text-base font-semibold text-gray-50 tracking-wide uppercase">Legal</h2>
        <p class="mt-1 text-4xl font-extrabold text-gray-100 sm:text-5xl sm:tracking-tight lg:text-6xl">Privacy Policy</p>
        <p class="mt-3 text-base text-gray-500">Last updated: October 24th, 2021</p>
      </div>
    </div>
  </div>

  <div class="relative py-16 overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
      <div class="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
        <svg class="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-700" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
        </svg>
        <svg class="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-700" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
        </svg>
        <svg class="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-700" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
        </svg>
      </div>
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="text-lg max-w-prose mx-auto">
        <p class="text-xl text-gray-400 leading-8">Diamond Hands Hotel is one of the world’s first NFT avatar creation and minting Service. Created by Diamond Hands Hotel Limited. (“DHH”), Diamond Hands Hotel allows creators and celebrities (“Creators”) to create and launch their own bespoke drop of NFTs, with holders receiving exclusive benefits set by the creator that can only be accessed through the hotel. Holders are also able to trade their NFT collections with other users, chat in private rooms with other holders, and play community games within their community.</p>
      </div>
      <div class="mt-6 prose prose-pink prose-lg text-gray-400 mx-auto">
        <p>DHH is committed to protecting and respecting your privacy. This Privacy Policy (this “Policy”) sets out how we collect and process personal information about you when you visit our website at www.diamondhandshotel.com, when you use our Services (our “Service”), or when you otherwise do business or make contact with us.</p>
        <h2 class="text-lg text-gray-200">1. What information do we collect?</h2>
        <p>DHH collects data to enable us to operate the Service effectively, and to provide you with the best experiences on our website and through our Service. You provide some of this data to us directly, such as when you register to use our Service, contact us for support, respond to a survey, make an enquiry through our website, subscribe to a newsletter, contact us for support, or contact us as a prospective user, vendor, supplier, or consultant. We collect some of your data by recording how you interact with our website and our Service by, for example, using technologies such as cookies. We also obtain and process data in the context of making the Service available to you.
        </p>
        <p>
          You have choices about the data we collect. When you are asked to provide personal data, you may decline. However,  if you decide not to provide data that is necessary to enable us to make the Service available to you, you may not be able to sign up for or use the Service. The data we collect depends on the context and nature of your interactions with DHH, and the choices you make (including your privacy settings). The data we collect can include the following:
        </p>
        <p>Name, Email and Metamask Wallet Address. We may collect your email address and your Metamask wallet address.</p>
        <p>Device and Usage information. We may collect data about your device and how you and your device interact with DHH and our Service. For example, we may collect your interactions on our website, your usage patterns of features on the platform, location data, and interactions you have with us. We may also collect data about your device and the network you use to connect to our Service; this may include data such as your IP address, browser type, operating system, and referring URLs.</p>
        <h2 class="text-lg text-gray-200">2. What do we use your information for?</h2>
        <p>
          We use the data we collect to operate our business, and to make our Service available to you. This includes using data collected to improve our Service, and to personalize your experiences on the platform. We may also use the data to communicate with you to, among other things, provide security updates, inform you about your account, and give you additional information about the Service. We may also utilise the data to manage your email subscriptions, improve the relevance and security of our website, respond to user queries, send you periodic marketing communications about our Services, and improve the accuracy and relevance of our advertising.
        </p>
        <p>
          Generally - We use data to respond to your enquiries and requests relating to our Service, to create and administer your account, and to provide us with information and access to resources that you have requested from us. We also may use data for general business purposes, including, among other things, to improve customer service, to help us improve the content and functionality of our Service, to enforce our Terms of Use, to better understand our users, to protect against wrongdoing, and to generally manage our business.
        </p>
        <p>
          Providing and improving our Service - We use collected data to provide the Service to you, to complete essential business operations, and to improve the Service for our users. This includes operating the Service, maintaining and improving the performance of the Service, developing new features, conducting research, and offering customer support. Examples of such uses include the following:
        </p>
        <p>Providing the Service - We use data to carry out your transactions with us and to make the Service available to you. In certain cases, the Service may include personalized features and recommendations to enhance your enjoyment, and to automatically tailor your experience based on the data collected.</p>
        <p>Improving Advertising Campaigns - We may use your data to improve our advertising campaigns, primarily in an effort to prevent targeting you with advertisements that are not relevant to you.</p>
        <p>Technical support - We use data to diagnose problems, and to provide customer care and other support Services.</p>
        <p>Sending Periodic Emails - We may use your data to send you periodic emails. Depending on the marketing preferences you select on your privacy dashboard, we may send you occasional marketing emails about our Service, which you can unsubscribe from at any time using the link provided in the message.</p>
        <p>Improving the Service - We use data to continually improve our website and our Service, including system administration, system security, adding new features or capabilities, and maintaining existing services.</p>
        <p>Business Operations - We use data to develop business intelligence that enable us to operate, protect, make more informed decisions, and report on the performance of our business.</p>
        <p>Promotions - We may use your data to administer contests, giveaways, promotions, surveys, or other site features.</p>
        <p>Communications - We use data we collect to communicate with you, and to personalize those communications with you. For example, we may contact you to discuss your account, to remind you about features of the Service that are available for your use, to update you about a support request, or to invite you to participate in a competition or survey. Additionally, you can sign up for email subscriptions, and have the option to receive marketing communications from us.</p>
        <h2 class="text-lg text-gray-200">3. How do we protect your information?</h2>
        <p>We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. We offer the use of a secure server. All supplied sensitive information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential.</p>
        <h2 class="text-lg text-gray-200">4. How do we ensure that our processing systems remain confidential, resilient, and available?</h2>
        <p>We implement a variety of measures to ensure that our processing systems remain confidential, resilient, and available. Specifically, we have implemented processes to help ensure business continuity, high availability, and prompt disaster recovery. We commit to maintaining strong physical and logical access controls, and conduct penetration testing to identify and address potential vulnerabilities in our Service and surrounding systems.</p>
        <p>Business Continuity - We keep encrypted backups of data daily in multiple regions on Amazon Web Services (AWS). While never expected, in the case of production data loss (i.e., primary data stores loss), we can restore organizational data from these backups.</p>
        <p>High Availability - Every part of the Service utilizes properly-provisioned, redundant servers (e.g., multiple load balancers, web servers, replica databases) in case of failure.</p>
        <p>Physical Access Controls - Diamond Hands Hotel is hosted by Cloudflare, one of the largest web infrastructure providers. DHH employees do not have physical access to Cloudflare data centers, servers, network equipment, or storage.</p>
        <p>Logical Access Controls - DHH is the assigned administrator of its infrastructure on Cloudflare, and only designated authorized DHH operations team members have access to configure the infrastructure on an as-needed basis behind a two-factor authenticated network.</p>
        <h2 class="text-lg text-gray-200">5. Do we use cookies?</h2>
        <p>Yes. Cookies are small files that a site or its Service provider transfers to your computer's hard drive through your Web browser (if you allow them) that enables the sites or Service providers systems to recognize your browser and capture and remember certain information. You may choose to disable cookies, but if you do, your ability to use or access certain parts of the Service or of our website may be limited.</p>
        <p>You may refuse to accept cookies by activating the setting on your browser that allows you to refuse the setting of cookies. However, if you choose to disable cookies, you may be unable to access certain parts of our site. A banner asking you to accept our cookies policy will be displayed upon your first visit to our website (or the first visit after you delete your browser's cookies). Unless you have adjusted your browser setting so that it will refuse cookies and/or you have not accepted our cookies policy, our system will issue cookies when you log on to our site.</p>
        <p>Our web pages may contain electronic images known as web beacons (also called single-pixel gifs) that we use to help deliver cookies on our websites, and to count users who have visited those websites, and to gather usage and performance data. We may also include web beacons in our promotional email messages or newsletters, to determine whether and when you open and act on them.</p>
        <p>In addition to placing web beacons on our own websites, we occasionally work with other companies to place our web beacons on their websites or in their advertisements. This helps us to develop statistics on how often clicking on an advertisement on a DHH website results in a purchase or other action on the advertiser's website.</p>
        <p>Finally, our Service may contain web beacons or similar technologies from third-party analytics providers that allow us to compile aggregated statistics about the effectiveness of our promotional campaigns or other operations. These technologies enable the analytics providers to set or read their own cookies or other identifiers on your device, through which they can collect information about your online activities across applications, websites or other products.</p>
        <h2 class="text-lg text-gray-200">6. Do we disclose any information to outside parties?</h2>
        <p>We share your personal data with your consent, or as necessary to make the Service available to you. We may also share your data with vendors working on our behalf; when required by law or to respond to legal process; to protect our customers; to protect lives; to maintain the security and integrity of our Service; and to protect our rights or our property.</p>
        <p>
          We share your personal data with your consent, or as necessary to make the Service available to you. We also share personal data with vendors or agents working on our behalf for the purposes described in this Policy. For example, companies we have hired to provide cloud hosting Services, off-site backups, and customer support may need access to personal data to provide those functions. In such cases, these companies are required to abide by our data privacy and security requirements and are not allowed to use personal data they receive from us for any other purpose. If you have questions or concerns about any of our vendors, feel free to contact us at frontdesk@diamondhandshotel.com.
        </p>
        <p>We may disclose your personal data as part of a corporate transaction such as a corporate sale, merger, reorganization, dissolution, or similar event. Finally, we will access, transfer, disclose, and/or preserve personal data, when we have a good faith belief that doing so is necessary to:</p>
        <p>(1) comply with applicable law or respond to valid legal process, judicial orders, or subpoenas;</p>
        <p>(2) respond to requests from public or governmental authorities, including for national security or law enforcement purposes;</p>
        <p>(3) operate and maintain the security or integrity of our Service, including to prevent or stop an attack on our computer systems or networks;</p>
        <p>(4) protect the rights, interests or property of DHH or third parties;</p>
        <p>(5) protect the vital interests of our users, customers, or other third parties (including, for example, to prevent spam or attempts to defraud users of our products, or to help prevent the loss of life or serious injury of anyone);</p>
        <p>(6) prevent or investigate possible wrongdoing in connection with the Service; or</p>
        <p>(7) enforce our Terms of Use.</p>
        <p>We may use and share aggregated non-personal information with third parties for marketing, advertising, and analytics purposes. We absolutely do not sell or trade your personal information to third parties.</p>
        <h2 class="text-lg text-gray-200">7. How to Access and Control Your Personal Data</h2>
        <p>You can view, access, edit, delete, or request a copy of your personal data for many aspects of the Service via your user dashboard. You can also make choices about DHH’s collection and use of your data. You can always choose whether you want to receive marketing communications from us. You can also opt out from receiving all marketing communications from us by using the opt-out link at the bottom of the communication, or by visiting your user dashboard.</p>
        <p>Data Access</p>
        <p>You can access your personal data on your account’s user dashboard.</p>
        <p>Data Portability</p>
        <p>You can request a copy of your personal data by submitting an email to us at frontdesk@diamondhandshotel.com and including “Please send me a copy of my personal data” in the “Subject” line. DHH will verify your ability to access that email, then send you a digital export of the data we hold that is associated with your email address. We will use reasonable efforts to respond to your request within 14 days, but in all events within 30 days of our receipt of the request. DHH may be limited in its ability to send certain personal data to you (e.g., the identification of your Metamask wallet).
        </p>
        <p>Data Erasure</p>
        <p>You may request that DHH delete your personal data by submitting an email to us at frontdesk@diamondhandshotel.com and including “Please delete my personal data” in the “Subject” line. DHH will verify your ability to access that email, then delete the personal data associated with your email address. We will use reasonable efforts to respond to your request within 14 days, but in all events within 30 days of our receipt of the request.</p>
        <p>Data Correction</p>
        <p>You can modify your personal data on your account’s user dashboard. Note that since some of the data we collect is specific to you – for example, your Metamask wallet address – you may not be able to modify this data without needing to create a new user profile.</p>
        <p>Your Communications Preferences</p>
        <p>You can choose whether you wish to receive marketing communications from us. If you receive marketing communications from us and would like to opt out, you can do so by following the directions in that communication. You can also make choices about your receipt of marketing communications by signing into your account, and viewing and managing your communication permissions in your account’s user dashboard, where you can update contact information, manage your contact preferences, opt out of email subscriptions, and choose whether to share your contact information with DHH and our partners.</p>
        <p>Alternatively, you can request that we withdraw consent to use your personal data by submitting an email to us at frontdesk@diamondhandshotel.com and including “Please withdraw my consent for marketing communications” in the “Subject” line. DHH will verify your ability to access that email, then update our systems to remove your email address from the system we use to send marketing communications. We will use reasonable efforts to respond to your request within 14 days, but in all events within 30 days of our receipt of the request. Please note that these choices do not apply to mandatory communications that are part of the Service, or to surveys or other informational communications that have their own unsubscribe method.</p>
        <h2 class="text-lg text-gray-200">8. Third Party Links</h2>
        <p>Occasionally, at our discretion, we may include or offer third party products or Services on our website or through our Service. If you access other websites using the links provided, the operators of these websites may collect information from you that will be used by them in accordance with their privacy policies. These third party sites have separate and independent privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites</p>
        <h2 class="text-lg text-gray-200">9. Where we Store and Process Personal Data; International Transfers</h2>
        <p>Personal data collected by DHH may be stored and processed in Canada or in any other country where DHH or its affiliates, subsidiaries or Service providers maintain facilities. The storage location(s) are chosen in order to improve performance, operate efficiently, and to create redundancies in order to protect the data in the event of an outage or other issue. We take steps to ensure that the data we collect is processed according to the provisions of this Policy, and the requirements of applicable law wherever the data is located.</p>
        <p>We transfer personal data from the European Economic Area and Switzerland to other countries, some of which have not been determined by the European Commission to have an adequate level of data protection. When we engage in such transfers, we use a variety of legal mechanisms, including contracts, to help ensure your rights and protections travel with your data. To learn more about the European Commission’s decisions on the adequacy of the protection of personal data in the countries where DHH processes personal data, please visit: ec.europa.eu/justice/data-protection/international-transfers/adequacy/index_en.htm</p>
        <h2 class="text-lg text-gray-200">10. Data Retention</h2>
        <p>We may retain your personal information as long as you continue to use the Service, have an account with us, or for as long as is necessary to fulfill the purposes outlined in this Policy. You can ask to close your account by contacting us as described above, and we will delete your personal information on request. We may, however, retain personal information for an additional period as is permitted or required under applicable laws, for legal, tax, or regulatory reasons, or for legitimate and lawful business purposes.</p>
        <p>We will retain your personal data for as long as necessary to make the Service available to you, or for other essential purposes such as complying with our legal obligations, resolving disputes, and enforcing our agreements. Because these needs can vary for different types of data, actual retention periods can vary significantly. The criteria we use to determine the retention periods include:</p>
        <p>How long is the personal data needed to make the Service available to you and/or operate our business? This includes such things such as maintaining and improving the performance of the Service, keeping our systems secure, and maintaining appropriate business and financial records. This is the general rule that establishes the baseline for most data retention periods.</p>
        <p>Is there an automated control, such as in your user dashboard, that enables you to access and delete the personal data at any time? If there is not, a shortened data retention time will generally be adopted.</p>
        <p>Is the personal data of a sensitive type? If so, a shortened retention time would generally be appropriate.</p>
        <p>Has the user provided consent for a longer retention period? If so, we will retain the data in accordance with your consent.</p>
        <p>Is DHH subject to a legal, contractual, or similar obligation to retain the data? Examples can include mandatory data retention laws in the applicable jurisdiction, government orders to preserve data relevant to an investigation, or data that must be retained for the purposes of litigation.</p>
        <h2 class="text-lg text-gray-200">11. Changes to our Privacy Policy</h2>
        <p>We will update this privacy statement when necessary to reflect customer feedback and changes to our Service. When we post changes to this statement, we will revise the "last updated" date at the top of the statement. If there are material changes to the statement or in how DHH will use or process your personal data, we will notify you either by prominently posting a notice of such changes before they take effect or by sending you a notification directly. We encourage you to periodically review this privacy statement to learn about and to stay updated on how DHH is protecting your information.</p>
        <h2 class="text-lg text-gray-200">12. How to Contact Us</h2>
        <p>If you have a technical or support question, please send us an email at frontdesk@diamondhandshote.com</p>
        <p>If you have a privacy concern, complaint, or a question for the Data Protection Officer of DHH, please contact us by sending us an email at frontdesk@diamondhandshote.com. We will respond to questions or concerns within 30 days.</p>
        <p>Unless otherwise stated, DHH is a data controller for personal data we collect through the Service subject to this statement. Our address is Diamond Hands Hotel Limited, 20-22 Wenlock Road, London, N1 7GU.</p>
      </div>
    </div>
  </div>
</template>

<script setup>

</script>